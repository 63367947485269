import { useCallback, useEffect, useRef, useState } from 'react';
import { LinearProgress, Grid, Alert } from '@mui/material';

import { useMutation, useQuery } from '@apollo/client';
import { gql } from 'apollo-boost';
import { useParams } from 'react-router-dom';
import { odontogramNotesQuery } from 'graphql/querys/odontogramNotesQuery';
import { UserOdontogramNotesForm } from './UserOdontogramNotesForm';

export function UserOdontogramNotes() {
  const { id } = useParams();
  const [message, setMessage] = useState(null);
  const divRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setMessage(null);
    }, 5000);
  }, [message]);

  const [
    updateOdontodiagram,
    {
      data: updateOdontodiagramData,
      loading: updateOdontodiagramLoading,
      error: updateOdontodiagramError
    }
  ] = useMutation(
    gql`
      mutation updateOdontodiagram($id: ID!, $medicalHistoryData: PatientMedicalHistoryInput!) {
        updateMedicalHistory(id: $id, medicalHistoryData: $medicalHistoryData) {
          medicalHistoryData {
            odontodiagram_notes
          }
        }
      }
    `
  );

  const { data: pData, loading: dataLoading } = useQuery(odontogramNotesQuery, {
    variables: { id },
    fetchPolicy: 'no-cache'
  });

  const handleUpdate = useCallback(
    (newNotes) => {
      updateOdontodiagram({
        variables: {
          id,
          medicalHistoryData: {
            odontodiagram_notes: newNotes
          }
        }
      })
        .then(() => {
          setMessage('Las notas han sido editadas');
        })
        .catch((e) => {
          alert(`${e}. Please contact administrators`);
        });
    },
    [id, updateOdontodiagram]
  );

  if (dataLoading || !pData) {
    return (
      <>
        <Grid container rowSpacing={2} columnSpacing={3}>
          <LinearProgress />
        </Grid>
      </>
    );
  }
  return (
    <>
      <UserOdontogramNotesForm
        initialValue={pData.medicalHistoryByPatientId.odontodiagram_notes}
        onSubmit={handleUpdate}
      />
      {message && (
        <>
          <br />
          <Alert variant="outlined" severity="success" style={{ marginTop: '20px' }}>
            {message}
          </Alert>
        </>
      )}
    </>
  );
}
