import { Grid, Typography, TextField as TextFieldMaterial } from '@mui/material';
import { Form, Formik } from 'formik';

import { useEffect, useState } from 'react';
import SubmitButtonWrapper from 'components/Form/SubmitButtonWrapper';
import * as yup from 'yup';
import { EditTreatments } from './EditTreatments';
import { TreatmentPlanProgress } from './TreatmentPlanProgress';
import TextField from '../../Form/TextFieldWrapper';

const FORM_VALIDATION = yup
  .object()
  .shape({
    name: yup.string().required('El nombre del plan de tratamiento es necesario')
  })
  .required();

const INITAL_FORM_STATE = {
  name: ''
};

export function TreatmentPlanForm(props) {
  const [treatments, setTreatments] = useState([]);

  const sumOfPrices = treatments.map((t) => t.price).reduce((curr, next) => curr + next, 0);
  const treatmentsWithoutId = treatments.map((t) => ({
    id: t.id || undefined,
    name: t.name,
    price: t.price
  }));

  return (
    <Formik
      initialValues={props.treatmentPlan || INITAL_FORM_STATE}
      validationSchema={FORM_VALIDATION}
      onSubmit={(_values) => {
        props.onSubmit({
          name: _values.name,
          treatments: treatmentsWithoutId
        });
      }}
    >
      {({ errors }) => (
        <>
          <Typography variant="h5" gutterBottom pt={3}>
            Información del plan de tratamiento
          </Typography>

          <Grid
            container
            rowSpacing={5}
            columnSpacing={3}
            columns={{ xs: 6, sm: 6, md: 12 }}
            p={{ xs: 2, sm: 3 }}
          >
            <Grid item xs={9}>
              <TextField name="name" label="Nombre del plan de tratamiento" fullWidth />
              <br />
              <br />
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'center' }}>
              <Typography mb="10px">Progreso del plan</Typography>
              <TreatmentPlanProgress value={0} over={treatments.length} size={60} />
            </Grid>
          </Grid>
          <EditTreatments
            onTreatmentsChangeHandler={setTreatments}
            treatments={props.treatmentPlan?.treatments}
            allowRemove
          />
          <br />
          <br />
          <Grid
            container
            rowSpacing={5}
            columnSpacing={3}
            columns={{ xs: 6, sm: 6, md: 12 }}
            p={{ xs: 2, sm: 3 }}
          >
            <Grid item xs={9} />
            <Grid item xs={3} style={{ textAlign: 'center' }}>
              <div style={{ textAlign: 'right' }}>
                <TextFieldMaterial
                  InputProps={{
                    readOnly: true
                  }}
                  type="number"
                  variant="standard"
                  label="Monto total"
                  value={sumOfPrices}
                />
              </div>
            </Grid>
          </Grid>
          <br />
          <br />
          <SubmitButtonWrapper disabled={Object.keys(errors).length > 0}>
            {props.treatmentPlan ? 'Editar' : 'Crear'} Plan de Tratamiento
          </SubmitButtonWrapper>
        </>
      )}
    </Formik>
  );
}
