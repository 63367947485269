import { gql } from '@apollo/client';

export const updateTreatmentPlan = gql`
  mutation UpdateTreatmentPlan($treatmentPlan: TreatmentPlanUpdateInput!) {
    updateTreatmentPlan(treatmentPlan: $treatmentPlan) {
      treatmentPlanData {
        id
      }
      treatmentPlanError {
        field
        message
      }
    }
  }
`;
