import { useMutation, useQuery } from '@apollo/client';
import { Box, Card, Skeleton, Stack, Typography } from '@mui/material';
import { TreatmentPlanForm } from 'components/_dashboard/consultations/TreatmentPlanForm';
import { updateTreatmentPlan } from 'graphql/mutations/updateTreatmentPlan';
import { treatmentPlansByPatientId } from 'graphql/querys/treatmentPlansByPatientId';
import { useNavigate, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';

export default function EditTreatmentPlan() {
  const navigate = useNavigate();

  const { id, treatmentPlanId } = useParams();

  const [update] = useMutation(updateTreatmentPlan, {
    refetchQueries: [{ query: treatmentPlansByPatientId, variables: { id } }],
    awaitRefetchQueries: true
  });

  const { data: pData, loading: dataLoading } = useQuery(treatmentPlansByPatientId, {
    variables: { id }
  });
  if (dataLoading) {
    return <Skeleton />;
  }
  const treatmentPlan = pData.treatmentPlansByPatientId.filter(
    (tp) => tp.id === treatmentPlanId
  )[0];

  const onSubmit = async (values) => {
    const result = await update({
      variables: {
        treatmentPlan: {
          id: treatmentPlanId,
          ...values
        }
      }
    });
    if (result?.data?.updateTreatmentPlan?.treatmentPlanData?.id) {
      navigate(-1);
    } else {
      const msg = result?.data?.updateTreatmentPlan?.treatmentPlanError?.message;
      const err = new Error(
        `failed to update treatment plan ${treatmentPlanId} for user ${id}: ${msg}`
      );
      Sentry.captureException(err);
      alert(err);
    }
  };
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} ml={5}>
        <Typography variant="h4" gutterBottom>
          Editar plan de tratamiento
        </Typography>
      </Stack>
      <Box px={{ xs: 1, sm: 2, md: 5 }}>
        <Card sx={{ boxShadow: 3 }}>
          <Box px={{ xs: 1, sm: 5 }} py={3}>
            <TreatmentPlanForm onSubmit={onSubmit} treatmentPlan={treatmentPlan} />
          </Box>
        </Card>
      </Box>
    </>
  );
}
