import {
  Box,
  Button,
  Card,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { dataURLtoFile } from '../../../utils/s3Actions';
import { UserMoreMenu } from '../user';

const TABLE_HEAD = [
  // THE id here is used to control the sort value, it has to correspond to the value to be compared
  { id: 'signatureDate', label: 'Fecha', alignRight: false }
];

function formattedDate(d = new Date()) {
  return [d.getDate(), d.getMonth() + 1, d.getFullYear()]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join('/');
}

export function InsertSignature(props) {
  const signRef = useRef();

  const handleClear = () => {
    signRef.current.clear();
  };

  const handleSave = async () => {
    const signatureCanvas = signRef.current.getTrimmedCanvas();
    const signatureDataUrl = signatureCanvas.toDataURL('image/png');

    // Create a new canvas to combine the image and the signature
    const combinedCanvas = document.createElement('canvas');
    const context = combinedCanvas.getContext('2d');

    // Load the original image
    const image = new Image();
    image.src = '/static/writtenConsentment.png';
    image.onload = async () => {
      // Define the scale factor for the signature
      const scaleFactor = 1.5; // Adjust this value to make the signature larger
      const paddingBottom = 110; // Add padding at the bottom for text

      // Calculate new dimensions for the signature
      const scaledSignatureWidth = signatureCanvas.width * scaleFactor;
      const scaledSignatureHeight = signatureCanvas.height * scaleFactor;

      // Set the canvas size to the width of the image and the combined height of the image, scaled signature, and padding
      combinedCanvas.width = image.width;
      combinedCanvas.height = image.height + scaledSignatureHeight + paddingBottom;

      // Fill the canvas with a white background
      context.fillStyle = '#fff';
      context.fillRect(0, 0, combinedCanvas.width, combinedCanvas.height);

      // Draw the original image onto the canvas
      context.drawImage(image, 0, 0);

      // Calculate the x position to center the scaled signature
      const signatureX = (combinedCanvas.width - scaledSignatureWidth) / 2;

      // Draw the scaled signature below the image, centered
      context.drawImage(
        signatureCanvas,
        signatureX,
        image.height,
        scaledSignatureWidth,
        scaledSignatureHeight
      );

      // Set text properties
      context.fillStyle = '#666'; // Lighter text color
      context.font = '30px Arial'; // Font size
      context.textAlign = 'center'; // Center the text

      // Draw the text below the signature with additional padding
      context.fillText(
        'Firma del Paciente',
        combinedCanvas.width / 2,
        image.height + scaledSignatureHeight + 60
      );

      // Get the combined image as a data URL
      const combinedDataUrl = combinedCanvas.toDataURL('image/png');
      const file = await dataURLtoFile(combinedDataUrl, 'signed_image');
      file.date = formattedDate(new Date(Number(file.lastModified)));
      props.setSignatureFile([file]);
      handleClear();
    };
  };

  const handleDeleteSelected = () => {
    props.setSignatureFile([]);
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={12} style={{ textAlign: 'left' }}>
            <Typography variant="h4" gutterBottom>
              Ingresar firma
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Box m={3} display="flex" justifyContent="center" alignItems="center">
              <Card>
                <img
                  src="/static/writtenConsentment.png"
                  alt="Your"
                  style={{ width: '450px', height: '500px' }}
                />
                <SignatureCanvas
                  ref={signRef}
                  penColor="#16537e"
                  canvasProps={{
                    width: 200,
                    height: 200,
                    style: {
                      border: '1px solid #ccc' // Gray border, 1px width
                    } // Add a border with the desired width and color
                  }}
                  // onBegin={handleLockUpdate}
                  // onEnd={handleUpdateData}
                />
              </Card>
            </Box>
          </Grid>
          <Grid mb={2} item xs={6} style={{ textAlign: 'right' }}>
            <Box>
              <Button variant="contained" onClick={handleClear}>
                Borrar
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'left' }}>
            <Box>
              <Button variant="contained" onClick={handleSave}>
                Ingresar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Stack>
      <Card sx={{ boxShadow: 3 }}>
        {props.signatureFile.length !== 0 && (
          <Box py={2}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Firma ingresada</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.signatureFile.map((file) => (
                    <TableRow
                      key={file.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {file.date}
                      </TableCell>
                      <TableCell align="right">
                        <UserMoreMenu handleDelete={() => handleDeleteSelected()} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Card>
    </>
  );
}
