import { useMutation, useQuery } from '@apollo/client';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow
} from '@mui/material';
import { deleteMultiplePaymentsByIdMutation } from 'graphql/mutations/deleteMultiplePaymentsById';
import { deleteMultiplePaymentsByIdOnConsultationMutation } from 'graphql/mutations/deleteMultiplePaymentsByIdOnConsultation';
import { consultationById } from 'graphql/querys/consultationByIdQuery';
import { ConsultationsByPatientId } from 'graphql/querys/consultationsByPatientIdQuery';
import { ConsultationsOwedByPatientId } from 'graphql/querys/consultationsOwedByPatientIdQuery';
import { paymentByConsultationId } from 'graphql/querys/paymentByConsultationIdQuery';
import { paymentByPatientId } from 'graphql/querys/paymentByPatientIdQuery';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import { fromUTCdatetimeToLocalDate } from 'utils/formatTime';
import Scrollbar from '../Scrollbar';
import SearchNotFound from '../SearchNotFound';
import { UserListHead } from '../_dashboard/user';
import ContextPaymentMenu from './ContextPaymentMenu';

PaymentTableContent.propTypes = {
  selected: PropTypes.array.isRequired,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  handleFilterByName: PropTypes.func,
  handleRequestSort: PropTypes.func,
  handleSelectAllClick: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  emptyRows: PropTypes.number,
  isPaymentNotFound: PropTypes.bool,
  TABLE_HEAD: PropTypes.array,
  filteredPayments: PropTypes.array,
  handleClick: PropTypes.func,
  isDeletedOnConsultation: PropTypes.bool
};

export default function PaymentTableContent({
  selected,
  filterName,
  handleRequestSort,
  handleSelectAllClick,
  handleChangePage,
  handleChangeRowsPerPage,
  order,
  orderBy,
  TABLE_HEAD,
  filteredPayments,
  page,
  rowsPerPage,
  emptyRows,
  isPaymentNotFound,
  isConsultation,
  isDeletedOnConsultation
}) {
  const { id, consultationId } = useParams();
  const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
  const [displayDeleteError, setDisplayDeleteError] = React.useState(null);
  const [displayDeleteSuccess, setDisplayDeleteSuccess] = React.useState(null);
  const [paymentIdToDelete, setPaymentIdToDelete] = React.useState(null);

  const [deleteMultiplePaymentsByIdOnPatient] = useMutation(deleteMultiplePaymentsByIdMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: ConsultationsByPatientId, variables: { consultationsByPatientIdId: id } },
      { query: paymentByPatientId, variables: { paymentByPatientIdId: id } },
      { query: ConsultationsOwedByPatientId, variables: { id } },
      ...(consultationId
        ? [
            {
              query: paymentByConsultationId,
              variables: { paymentByConsultationIdId: consultationId }
            },
            {
              query: consultationById,
              variables: { consultationByIdId: consultationId }
            }
          ]
        : [])
    ]
  });

  const [deleteMultiplePaymentsByIdOnConsultation] = useMutation(
    deleteMultiplePaymentsByIdOnConsultationMutation,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: ConsultationsByPatientId, variables: { consultationsByPatientIdId: id } },
        { query: paymentByPatientId, variables: { paymentByPatientIdId: id } },
        { query: ConsultationsOwedByPatientId, variables: { id } },
        ...(consultationId
          ? [
              {
                query: paymentByConsultationId,
                variables: { paymentByConsultationIdId: consultationId }
              },
              {
                query: consultationById,
                variables: { consultationByIdId: consultationId }
              }
            ]
          : [])
      ]
    }
  );

  const { refetch: refetchPaymentData } = useQuery(paymentByPatientId, {
    variables: { paymentByPatientIdId: +id }
  });

  const { refetch: refetchConsultationById } = useQuery(consultationById, {
    variables: { consultationByIdId: +consultationId }
  });

  const { refetch: refetchConsultationData } = useQuery(ConsultationsByPatientId, {
    variables: { consultationsByPatientIdId: +id }
  });

  const handleDeleteSelected = async () => {
    setDeleteConfirmModal(false);
    let deletion;

    if (isDeletedOnConsultation) {
      deletion = await deleteMultiplePaymentsByIdOnConsultation({
        variables: {
          paymentsData: {
            payment_id: [paymentIdToDelete]
          }
        }
      });
    } else {
      deletion = await deleteMultiplePaymentsByIdOnPatient({
        variables: {
          paymentsData: {
            patient_id: parseInt(id, 10),
            payment_id: [paymentIdToDelete]
          }
        }
      });

      const paymentToDelete = filteredPayments.find(
        (p) => p.paymentId === paymentIdToDelete.toString()
      );
      const consultationIdToUse = consultationId || paymentToDelete?.consultationId;
      console.log(consultationIdToUse);
      if (consultationIdToUse) {
        refetchConsultationById({
          consultationByIdId: consultationIdToUse.toString()
        });
      }
      refetchConsultationData();
      refetchPaymentData();
    }

    if (deletion?.data?.deleteMultiplePaymentsById?.paymentError) {
      setDisplayDeleteError(
        deletion.data.deleteMultiplePaymentsById.paymentError.message || 'Unknown error'
      );
    }

    if (deletion?.data?.deleteMultiplePaymentsById?.paymentData) {
      const movedMoney = deletion.data.deleteMultiplePaymentsById.paymentData.payed_amount;
      setDisplayDeleteSuccess(`$${movedMoney} han sido transferidos a otras Consultas por pagar`);
    }

    setPaymentIdToDelete(null);
  };

  const handleDelete = (paymentId) => {
    setPaymentIdToDelete(parseInt(paymentId, 10)); // Ensure paymentId is an integer
    setDeleteConfirmModal(true);
  };

  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={filteredPayments.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            {displayDeleteError && (
              <Alert variant="outlined" severity="error">
                {displayDeleteError}
              </Alert>
            )}
            {displayDeleteSuccess && (
              <Alert variant="outlined" severity="success">
                {displayDeleteSuccess}
              </Alert>
            )}
            <TableBody>
              {filteredPayments
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const {
                    paymentId,
                    paymentDate,
                    reference,
                    paymentMethod,
                    payedAmount,
                    consultationId,
                    consultationDescription,
                    event
                  } = row;

                  return (
                    <TableRow hover key={paymentId} tabIndex={-1}>
                      <TableCell align="left">{reference}</TableCell>
                      <TableCell align="left">{paymentDate}</TableCell>
                      <TableCell align="left">{paymentMethod}</TableCell>
                      <TableCell align="left">$ {payedAmount}</TableCell>
                      <TableCell align="left">
                        {consultationId
                          ? `${fromUTCdatetimeToLocalDate(event.begin)} ${consultationDescription}`
                          : 'Asignado automaticamente'}
                      </TableCell>
                      <TableCell align="left">
                        <ContextPaymentMenu handleDelete={() => handleDelete(paymentId)} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
            {isPaymentNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count !== -1 ? count : `Mas que ${to}`}`
        }
        labelRowsPerPage="Columnas por pagina"
        count={filteredPayments.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog
        open={deleteConfirmModal}
        onClose={() => setDeleteConfirmModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirma</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Quiere eliminar este pago?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteSelected} autoFocus>
            Eliminar
          </Button>
          <Button onClick={() => setDeleteConfirmModal(false)}>Cancelar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
