import React from 'react';
import { Box, Card, CardContent, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Consultations } from '../consultations/Consultations';
import { UserOdontogram } from './UserOdontogram';
import { PaymentHistory } from './PaymentHistory';
import { MedicalHistory } from './MedicalHistory';
import { TreatmentPlans } from './TreatmentPlans';
import { UserOdontogramNotes } from './UserOdontogramNotes';

export function UserDetailBody(props) {
  const defaultTab = props.default ? props.default : 'general';
  const [tab, setTab] = React.useState(defaultTab);
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const p = props.patient;
  return (
    <Card sx={{ boxShadow: 3, margin: { xs: -2, sm: 0 } }}>
      <CardContent sx={{ margin: { xs: -4, sm: 0 } }}>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChangeTab} aria-label="lab API tabs example" centered>
                <Tab label="General" value="general" />
                <Tab label="Planes de tratamiento" value="treatmentPlans" />
                <Tab label="Historia médica" value="medica" />
                <Tab label="Historia de pago" value="pago" />
              </TabList>
            </Box>
            <TabPanel value="general">
              <Consultations />
              <br />
              <UserOdontogram />
              <br />
              <UserOdontogramNotes />
            </TabPanel>
            <TabPanel value="treatmentPlans">
              <TreatmentPlans />
            </TabPanel>
            <TabPanel value="medica">
              <MedicalHistory />
            </TabPanel>
            <TabPanel value="pago">
              <PaymentHistory />
            </TabPanel>
          </TabContext>
        </Box>
      </CardContent>
    </Card>
  );
}
