import { useQuery } from '@apollo/client'; // material
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import {
  Button,
  Card,
  Container,
  Input,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { allGroupsQuery } from 'graphql/querys/allGroups';
import { Link as RouterLink } from 'react-router-dom';
// components
import externalLinkOutline from '@iconify/icons-eva/external-link-outline';
import Page from 'components/Page';
import { useState } from 'react';

export default function GroupList() {
  const [filterName, setFilterName] = useState('');
  const handleFilterName = (event) => {
    setFilterName(event.target.value);
  };

  const {
    data: allGroups,
    loading: allGroupsLoading,
    error: allGroupsError
  } = useQuery(allGroupsQuery);

  if (allGroupsLoading)
    return (
      <Page title="Dental by Aronin">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Groups
            </Typography>
          </Stack>
          <Card sx={{ boxShadow: 3 }}>
            <p>Loading data...</p>
          </Card>
        </Container>
      </Page>
    );
  if (allGroupsError) return <p>ERROR</p>;

  const groups = allGroups.getAllGroupsV2.filter((group) => group.GroupName.includes(filterName));
  return (
    <Page title="Dental by Aronin">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Groups
          </Typography>
          <Typography variant="h6" gutterBottom>
            Total: {allGroups.getAllGroupsV2.length}
          </Typography>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://us-east-2.console.aws.amazon.com/cognito/v2/idp/user-pools/${process.env.REACT_APP_COGNITO_USERPOOL_ID}/groups?region=${process.env.REACT_APP_COGNITO_REGION}`}
          >
            View in AWS (new tab)
          </a>
          <Button
            variant="contained"
            component={RouterLink}
            to="createGroup"
            startIcon={<Icon icon={plusFill} />}
          >
            Create Group
          </Button>
        </Stack>

        <Card sx={{ boxShadow: 3 }}>
          <div style={{ padding: '10px' }}>
            Search by name :
            <Input
              placeholder="search"
              sx={{ fontSize: '1.2em' }}
              value={filterName}
              onChange={handleFilterName}
            />
            {/* {allUsers && allUsers.getUsersByEmail.length === 60 && (
              <>
                <br />
                <br />
                showing only 60 first results
              </>
            )} */}
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Group Name</TableCell>
                  <TableCell>Creation Date</TableCell>
                  <TableCell>Last Modification Date</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {groups.map((group) => (
                  <TableRow key={group.GroupName}>
                    <TableCell>{group.GroupName}</TableCell>
                    <TableCell>
                      {new Date(group.CreationDate).toISOString().slice(0, 10).replace(/-/g, ' / ')}
                    </TableCell>
                    <TableCell>
                      {new Date(group.LastModifiedDate)
                        .toISOString()
                        .slice(0, 10)
                        .replace(/-/g, ' / ')}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Haga clic para ver los detalles">
                        <Button variant="outlined" component={RouterLink} to={group.GroupName}>
                          Manage
                          <Icon icon={externalLinkOutline} width={20} height={20} />
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Container>
    </Page>
  );
}
