import { Icon } from '@iconify/react';
import { useQuery } from '@apollo/client';
import { filter } from 'lodash';
import { Button, Card, Skeleton, Stack, Typography } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { treatmentPlansByPatientId } from 'graphql/querys/treatmentPlansByPatientId';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useState } from 'react';
import { getComparator } from '../../../utils/comparator';
import ConsultationToolbar from '../consultations/ConsultationToolbar';
import TreatmentPlansTableContent from '../treatmentPlans/TreatmentPlansTableContent';

const TABLE_HEAD = [
  // THE id here is used to control the sort value, it has to correspond to the value to be compared
  { id: 'name', label: 'Nombre del plan de tratamiento', alignRight: false },
  { id: 'treatments', label: 'Tratamientos', alignRight: false }
];

function applySortTreatmentPlanFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1]; // this allows us to keep original order in case of similar order values 'A LITTLE OVERKILL'
  });
  //  Pass query as parameter for front side search
  if (query) {
    return filter(array, (_tp) => `${_tp.name}`.indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export function TreatmentPlans() {
  const { id } = useParams();

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('event.begin');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const { data: pData, loading: dataLoading } = useQuery(treatmentPlansByPatientId, {
    variables: { id }
  });
  if (dataLoading) {
    return <Skeleton />;
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - pData.treatmentPlansByPatientId.length) : 0;

  const filteredTreatmentPlans = applySortTreatmentPlanFilter(
    pData.treatmentPlansByPatientId,
    getComparator(order, orderBy),
    filterName
  );
  const isNoResult = filteredTreatmentPlans.length === 0;

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4">Planes de tratamiento</Typography>
        <Button
          variant="contained"
          component={RouterLink}
          to={`/dashboard/createTreatmentPlan/${id}`}
          startIcon={<Icon icon={plusFill} />}
        >
          Nuevo plan de tratamiento
        </Button>
      </Stack>
      <Card sx={{ boxShadow: 3 }}>
        <ConsultationToolbar
          filterName={filterName}
          onFilterName={handleFilterByName}
          placeholder="Buscar planes"
        />

        <TreatmentPlansTableContent
          filterName={filterName}
          handleRequestSort={handleRequestSort}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          order={order}
          orderBy={orderBy}
          TABLE_HEAD={TABLE_HEAD}
          filteredTreatmentPlans={filteredTreatmentPlans}
          page={page}
          rowsPerPage={rowsPerPage}
          emptyRows={emptyRows}
          isTreatmentPlansNotFound={isNoResult}
        />
      </Card>
    </>
  );
}
