import { useQuery } from '@apollo/client';
import { Alert, Card, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { filter } from 'lodash';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { toCamelCase } from '../../../utils/toCamelCase';
import ConsultationToolbar from '../consultations/ConsultationToolbar';
import SignatureTableContent from './SignatureTableContent';
import { signatureDataFilesQuery } from '../../../graphql/querys/signatureDataFilesQuery';
import { revertFormattedDate, getComparator } from '../../../utils/comparator';

const TABLE_HEAD = [
  // THE id here is used to control the sort value, it has to correspond to the value to be compared
  { id: 'signatureDate', label: 'Fecha', alignRight: false }
];

// TODO: refactor to utils/comparator.js
function descendingComparator(a, b, orderBy) {
  const checkDateA = revertFormattedDate(a[orderBy]);
  const checkDateB = revertFormattedDate(b[orderBy]);
  if (checkDateA !== -1 && checkDateB !== -1) {
    // This means that a and b are dates
    if (checkDateB.getTime() < checkDateA.getTime()) {
      console.log('-1');
      return -1;
    }
    if (checkDateB.getTime() > checkDateA.getTime()) {
      console.log('1');
      return 1;
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    console.log('-1');
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    console.log('1');
    return 1;
  }
  return 0;
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1]; // this allows us to keep original order in case of similar order values 'A LITTLE OVERKILL'
  });
  //  Pass query as parameter for front side search
  if (query) {
    return filter(array, (_signature) => `${_signature.date}`.indexOf(query) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function formattedDate(d = new Date()) {
  return [d.getDate(), d.getMonth() + 1, d.getFullYear()]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join('/');
}

export function Signatures(props) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('event.begin');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { id } = useParams();

  const {
    refetch: refetchSignatureData,
    data: signatureData,
    loading: signatureLoading,
    error: signatureError
  } = useQuery(signatureDataFilesQuery, {
    variables: { patientId: id }
  });

  const signRef = useRef();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredSignatures.map((n) => n.key);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  if (signatureLoading) {
    return (
      <>
        <Alert severity="info">La carga de archivos puede demorar un momento.</Alert>
        <Skeleton animation="wave" />
      </>
    );
  }

  const signatures = toCamelCase(signatureData.getSignatureDataFiles);
  signatures.map(
    (signatures) => (signatures.date = formattedDate(new Date(Number(signatures.date))))
  );

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - signatures.length) : 0;

  const filteredSignatures = applySortFilter(signatures, getComparator(order, orderBy), filterName);

  const isSignatureNotFound = filteredSignatures.length === 0;
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Firmas
        </Typography>
      </Stack>
      <Grid container rowSpacing={2} columnSpacing={3}>
        <Grid item xs={12}>
          <Card sx={{ boxShadow: 3 }}>
            <ConsultationToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              placeholder="Buscar firma..."
            />

            <SignatureTableContent
              selected={selected}
              filterName={filterName}
              handleFilterByName={handleFilterByName}
              handleRequestSort={handleRequestSort}
              handleSelectAllClick={handleSelectAllClick}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              order={order}
              orderBy={orderBy}
              TABLE_HEAD={TABLE_HEAD}
              filteredSignatures={filteredSignatures}
              page={page}
              rowsPerPage={rowsPerPage}
              emptyRows={emptyRows}
              isSignatureNotFound={isSignatureNotFound}
              handleClick={handleClick}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
