import { gql } from '@apollo/client';

export const insertTreatmentPlan = gql`
  mutation InsertTreatmentPlan($treatmentPlan: TreatmentPlanInput!) {
    insertTreatmentPlan(treatmentPlan: $treatmentPlan) {
      treatmentPlanData {
        id
      }
      treatmentPlanError {
        field
        message
      }
    }
  }
`;
