// src/components/_dashboard/user/UserMedicalHistoryPdf.js
import { Document, Page as PDFPage, Text, View, StyleSheet } from '@react-pdf/renderer';
import palette from 'theme/palette';
import { format } from 'date-fns'; // Add this import for date formatting
import { dateFormater } from 'utils/dateFormater';

// Define styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontFamily: 'Helvetica',
    backgroundColor: 'white'
  },
  section: {
    marginBottom: 20,
    padding: 15,
    borderRadius: 8,
    border: `1 solid ${palette.primary.main}`
  },
  sectionTitle: {
    fontSize: 18,
    marginBottom: 10,
    color: palette.common.black,
    borderBottom: `1 solid ${palette.primary.main}`,
    paddingBottom: 5
  },
  fieldRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10
  },
  fieldName: {
    fontWeight: 'bold',
    fontSize: 14,
    marginBottom: 2
  },
  fieldValue: {
    fontSize: 12,
    width: '100%', // Ensure full width
    maxWidth: 500, // Set a max width suitable for A4
    marginBottom: 5, // Add spacing between fields
    wordWrap: 'break-word', // Enable word wrapping
    flexWrap: 'wrap' // Ensure text wraps within the container
  },
  fieldColumn: {
    flexDirection: 'column',
    width: '48%'
  },
  singleColumn: {
    flexDirection: 'column',
    marginBottom: 10,
    width: '100%' // Ensure full width
  }
});

const UserMedicalHistoryPDF = ({ userData }) => {
  const sex =
    // eslint-disable-next-line no-nested-ternary
    userData.sex === 'male' ? 'Masculino' : userData.sex === 'female' ? 'Femenino' : 'Otro';
  return (
    <Document>
      <PDFPage size="A4" style={styles.page}>
        {/* Personal Information */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Información Personal</Text>
          <View style={styles.fieldRow}>
            <View style={styles.fieldColumn}>
              <Text style={styles.fieldName}>Nombres:</Text>
              <Text style={styles.fieldValue}>{userData.firstName}</Text>
            </View>
            <View style={styles.fieldColumn}>
              <Text style={styles.fieldName}>Apellidos:</Text>
              <Text style={styles.fieldValue}>{userData.lastName}</Text>
            </View>
          </View>
          <View style={styles.fieldRow}>
            <View style={styles.fieldColumn}>
              <Text style={styles.fieldName}>Fecha de Nacimiento:</Text>
              <Text style={styles.fieldValue}>
                {(() => {
                  const date = new Date(userData.dateOfBirth);
                  const day = String(date.getUTCDate()).padStart(2, '0');
                  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
                  const year = date.getUTCFullYear();
                  return `${day}/${month}/${year}`;
                })()}
              </Text>
            </View>
            <View style={styles.fieldColumn}>
              <Text style={styles.fieldName}>Género:</Text>
              <Text style={styles.fieldValue}>{sex}</Text>
            </View>
          </View>
          <View style={styles.fieldRow}>
            <View style={styles.fieldColumn}>
              <Text style={styles.fieldName}>Cédula:</Text>
              <Text style={styles.fieldValue}>{userData.cedula}</Text>
            </View>
            <View style={styles.fieldColumn}>
              <Text style={styles.fieldName}>Correo Electrónico:</Text>
              <Text style={styles.fieldValue}>{userData.email}</Text>
            </View>
          </View>
          <View style={styles.fieldRow}>
            <View style={styles.fieldColumn}>
              <Text style={styles.fieldName}>Número de Teléfono:</Text>
              <Text style={styles.fieldValue}>{userData.phoneNumber}</Text>
            </View>
            <View style={styles.fieldColumn}>
              <Text style={styles.fieldName}>Número de Teléfono de Emergencia:</Text>
              <Text style={styles.fieldValue}>{userData.emergencyPhoneNumber}</Text>
            </View>
          </View>
          <View style={styles.fieldRow}>
            <View style={styles.fieldColumn}>
              <Text style={styles.fieldName}>Ocupación:</Text>
              <Text style={styles.fieldValue}>{userData.ocupation}</Text>
            </View>
          </View>
          <View style={styles.singleColumn}>
            <Text style={styles.fieldName}>Dirección:</Text>
            <Text style={{ ...styles.fieldValue, width: '100%' }}>{userData.address}</Text>
          </View>
        </View>

        {/* Medical History Information */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Anamnesis</Text>
          <View style={styles.singleColumn}>
            <Text style={styles.fieldName}>Alergias:</Text>
            <Text style={styles.fieldValue} wrap>
              {userData.allergies}
            </Text>
          </View>
          <View style={styles.singleColumn}>
            <Text style={styles.fieldName}>Antecedentes Personales:</Text>
            <Text style={styles.fieldValue} wrap>
              {userData.medicalAntecedents}
            </Text>
          </View>
          <View style={styles.singleColumn}>
            <Text style={styles.fieldName}>Historia Bucal:</Text>
            <Text style={styles.fieldValue} wrap>
              {userData.oralHistory}
            </Text>
          </View>
          <View style={styles.singleColumn}>
            <Text style={styles.fieldName}>Hábitos:</Text>
            <Text style={styles.fieldValue} wrap>
              {userData.habits}
            </Text>
          </View>
          <View style={styles.singleColumn}>
            <Text style={styles.fieldName}>Antecedentes Familiares:</Text>
            <Text style={styles.fieldValue} wrap>
              {userData.familyAntecedents}
            </Text>
          </View>
        </View>
      </PDFPage>
    </Document>
  );
};

export default UserMedicalHistoryPDF;
