/* eslint-disable camelcase */
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFound';
import { UserListHead } from '../user';

const FirstConfirmDialog = ({ open, onClose, onConfirm }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Confirma</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        ¿Está seguro que quiere eliminar esta consulta?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onConfirm} autoFocus>
        Eliminar
      </Button>
      <Button onClick={onClose}>Cancelar</Button>
    </DialogActions>
  </Dialog>
);

const SecondConfirmDialog = ({ open, onClose, onConfirm }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Confirma</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Toda la información relacionada a esta consulta será eliminada y no podrá ser recuperada,
        ¿desea continuar?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onConfirm} autoFocus>
        Eliminar
      </Button>
      <Button onClick={onClose}>Cancelar</Button>
    </DialogActions>
  </Dialog>
);

FirstConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

SecondConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

TreatmentTableContent.propTypes = {
  filterName: PropTypes.string,
  filteredTreatments: PropTypes.array,
  handleRequestSort: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  emptyRows: PropTypes.number,
  isTreatmentsNotFound: PropTypes.bool,
  TABLE_HEAD: PropTypes.array,
  selectable: PropTypes.bool,
  selectedTreatmentsIds: PropTypes.array,
  onSelectChange: PropTypes.func,
  editingConsultationId: PropTypes.string,
  readOnly: PropTypes.bool
};

export default function TreatmentTableContent({
  filterName,
  handleRequestSort,
  handleChangePage,
  handleChangeRowsPerPage,
  order,
  orderBy,
  TABLE_HEAD,
  filteredTreatments,
  page,
  rowsPerPage,
  emptyRows,
  isTreatmentsNotFound,
  selectable,
  selectedTreatmentsIds,
  onSelectChange,
  editingConsultationId,
  readOnly
}) {
  const [selectedTreatments, setSelectedTreatments] = useState([]);

  useEffect(() => {
    setSelectedTreatments(selectedTreatmentsIds);
  }, [selectedTreatmentsIds]);

  const handleCheck = (e) => {
    if (readOnly) return;
    const treatmentId = e.target.id;
    let updatedValue;
    if (!selectedTreatments.includes(treatmentId)) {
      updatedValue = [...selectedTreatments, treatmentId];
    } else {
      updatedValue = selectedTreatments.filter((v) => v !== treatmentId);
    }
    onSelectChange(updatedValue);
    setSelectedTreatments(updatedValue);
  };

  if (selectable) {
    TABLE_HEAD = [{ id: 'selected', label: 'Elegir', alignRight: false }, ...TABLE_HEAD];
  }
  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredTreatments
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const { id, name, price, consultation_id } = row;

                  return (
                    <TableRow
                      sx={{ textDecoration: 'none', color: 'inherit' }}
                      hover
                      key={id} // TODO CHANGE
                      tabIndex={-1}
                    >
                      {selectable && (
                        <TableCell align="left">
                          {!!consultation_id && consultation_id !== editingConsultationId ? (
                            <p>Asignado a otra consulta</p>
                          ) : (
                            <Checkbox
                              onChange={handleCheck}
                              id={id}
                              checked={selectedTreatments.includes(id)}
                            />
                          )}
                        </TableCell>
                      )}
                      <TableCell align="left">{name}</TableCell>
                      <TableCell align="left">{price}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isTreatmentsNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} onClick={(e) => e.stopPropagation()} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count !== -1 ? count : `Más que ${to}`}`
        }
        labelRowsPerPage="Columnas por pagina"
        count={filteredTreatments.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
