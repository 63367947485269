import React, { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow
} from '@mui/material';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { deletePatientFileMutation } from '../../graphql/mutations/deletePatientFile';
import { generatePatientDataDownloadURL } from '../../graphql/querys/generatePatientDataDownloadUrlQuery';
import { patientDataFilesQuery } from '../../graphql/querys/patientDataFilesQuery';
import { FileModal } from './FileModal'; // Import the FileModal component
import Scrollbar from '../Scrollbar';
import SearchNotFound from '../SearchNotFound';
import { UserListHead } from '../_dashboard/user';
import ContextFileMenu from './ContextFileMenu';

function DeleteConfirmDialog({ open, onClose, onConfirm }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirma</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Quiere eliminar este archivo?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} autoFocus>
          Eliminar
        </Button>
        <Button onClick={onClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  itemCount: PropTypes.number.isRequired
};

PatientFilesTableContent.propTypes = {
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  handleFilterByName: PropTypes.func,
  handleRequestSort: PropTypes.func,
  handleSelectAllClick: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  emptyRows: PropTypes.number,
  isFileNotFound: PropTypes.bool,
  TABLE_HEAD: PropTypes.array,
  filteredFiles: PropTypes.array
};

export default function PatientFilesTableContent({
  filterName,
  handleRequestSort,
  handleChangePage,
  handleChangeRowsPerPage,
  order,
  orderBy,
  TABLE_HEAD,
  filteredFiles,
  page,
  rowsPerPage,
  emptyRows,
  isFileNotFound
}) {
  const { id } = useParams();
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  const [openFileModal, setOpenFileModal] = useState(false);
  const [fileUrl, setFileUrl] = useState('');

  const [deletePatientFile, { loading: deletePatientFileLoading, error: deletePatientFileError }] =
    useMutation(deletePatientFileMutation, {
      refetchQueries: [{ query: patientDataFilesQuery, variables: { getPatientDataFilesId: id } }]
    });

  const [generatePatientDataDownloadURLQuery, { error, data: fileData, loading: fileLoading }] =
    useLazyQuery(generatePatientDataDownloadURL);

  const handleOpenFile = async (key) => {
    const result = await generatePatientDataDownloadURLQuery({
      variables: {
        patientId: id,
        fileId: key
      }
    });
    const url = result.data.generatePatientDataDownloadURL;
    setFileUrl(url);
    setOpenFileModal(true);
  };

  const handleDeleteSelected = () => {
    setDeleteConfirmModal(false);
    deletePatientFile({
      variables: {
        patientId: id,
        fileIds: [fileToDelete]
      }
    }).then(({ data }) => {
      console.log('this is the result', data);
      setFileToDelete(null);
    });
  };

  const handleDelete = (fileId) => {
    setFileToDelete(fileId);
    setDeleteConfirmModal(true);
  };

  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredFiles
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const { name, date, mimeType, key } = row;
                  return (
                    <TableRow onClick={() => handleOpenFile(key)} hover key={key} tabIndex={-1}>
                      <TableCell align="left">{name}</TableCell>
                      <TableCell align="left">{date}</TableCell>
                      <TableCell align="left">{mimeType}</TableCell>

                      <TableCell align="right">
                        <ContextFileMenu handleDelete={() => handleDelete(key)} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isFileNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count !== -1 ? count : `Mas que ${to}`}`
        }
        labelRowsPerPage="Columnas por pagina"
        count={filteredFiles.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <DeleteConfirmDialog
        open={deleteConfirmModal}
        onClose={() => setDeleteConfirmModal(false)}
        onConfirm={handleDeleteSelected}
        itemCount={1}
      />
      <FileModal
        open={openFileModal}
        onClose={() => setOpenFileModal(false)}
        presignedDownloadUrl={fileUrl}
      />
    </>
  );
}
