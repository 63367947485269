import { useQuery } from '@apollo/client';
import { Alert, Card, Skeleton, Stack, Typography } from '@mui/material';
import { filter } from 'lodash';
import { useState } from 'react';
import ListToolbar from 'components/_dashboard/user/ListToolbar';
import { useParams } from 'react-router-dom';
import { consultationDataFilesQuery } from '../../graphql/querys/consultationDataFilesQuery';
import { toCamelCase } from '../../utils/toCamelCase';
import ConsultationFilesTableContent from './ConsultationFilesTableContent';
import { revertFormattedDate, getComparator } from '../../utils/comparator';

const TABLE_HEAD = [
  // THE id here is used to control the sort value, it has to correspond to the value to be compared
  { id: 'name', label: 'Nombre', alignRight: false },
  { id: 'date', label: 'Fecha', alignRight: false },
  { id: 'type', label: 'Formato', alignRight: false }
];

// TODO: refactor to utils/comparator.js
function descendingComparator(a, b, orderBy) {
  const checkDateA = revertFormattedDate(a[orderBy]);
  const checkDateB = revertFormattedDate(b[orderBy]);
  if (checkDateA !== -1 && checkDateB !== -1) {
    // This means that a and b are dates
    if (checkDateB.getTime() < checkDateA.getTime()) {
      console.log('-1');
      return -1;
    }
    if (checkDateB.getTime() > checkDateA.getTime()) {
      console.log('1');
      return 1;
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    console.log('-1');
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    console.log('1');
    return 1;
  }
  return 0;
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1]; // this allows us to keep original order in case of similar order values 'A LITTLE OVERKILL'
  });
  //  Pass query as parameter for front side search
  if (query) {
    return filter(
      array,
      (_file) =>
        `${_file.name.toLowerCase()}`.indexOf(query.toLowerCase()) !== -1 ||
        `${_file.date}`.indexOf(query) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function formattedDate(d = new Date()) {
  return [d.getDate(), d.getMonth() + 1, d.getFullYear()]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join('/');
}

export function ConsultationFiles(props) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('lastName');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { consultationId } = useParams();

  const { data: fileData, loading: fileLoading } = useQuery(consultationDataFilesQuery, {
    variables: { consultationId }
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredFiles.map((n) => n.key);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, key) => {
    const selectedIndex = selected.indexOf(key);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, key);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  if (fileLoading) {
    return (
      <>
        <Alert severity="info">La carga de archivos puede demorar un momento.</Alert>
        <Skeleton animation="wave" />
      </>
    );
  }

  const files = toCamelCase(fileData.getConsultationDataFiles);
  console.log('this is file data', fileData);
  console.log(files);
  files.map((files) => (files.date = formattedDate(new Date(Number(files.date)))));
  console.log(files);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - files.length) : 0;

  const filteredFiles = applySortFilter(files, getComparator(order, orderBy), filterName);

  console.log(filteredFiles);
  const isFileNotFound = filteredFiles.length === 0;
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Archivos
        </Typography>
      </Stack>
      <Card sx={{ boxShadow: 3 }}>
        <ListToolbar
          filterName={filterName}
          onFilterName={handleFilterByName}
          placeholder="Buscar archivo..."
        />
        <ConsultationFilesTableContent
          selected={selected}
          filterName={filterName}
          handleFilterByName={handleFilterByName}
          handleRequestSort={handleRequestSort}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          order={order}
          orderBy={orderBy}
          TABLE_HEAD={TABLE_HEAD}
          filteredFiles={filteredFiles}
          page={page}
          rowsPerPage={rowsPerPage}
          emptyRows={emptyRows}
          isFileNotFound={isFileNotFound}
          handleClick={handleClick}
        />
      </Card>
    </>
  );
}
