import { gql } from '@apollo/client';

export const allGroupsQuery = gql`
  query Query {
    getAllGroupsV2 {
      CreationDate
      GroupName
      LastModifiedDate
      UserPoolId
    }
  }
`;
