import { useMutation } from '@apollo/client';
import { Box, Card, Stack, Typography } from '@mui/material';
import { TreatmentPlanForm } from 'components/_dashboard/consultations/TreatmentPlanForm';
import { insertTreatmentPlan } from 'graphql/mutations/insertTreatmentPlan';
import { treatmentPlansByPatientId } from 'graphql/querys/treatmentPlansByPatientId';
import { useNavigate, useParams } from 'react-router-dom';

export default function CreateTreatmentPlan(props) {
  const navigate = useNavigate();

  const { id } = useParams();

  const [insert] = useMutation(insertTreatmentPlan, {
    refetchQueries: [{ query: treatmentPlansByPatientId, variables: { id } }],
    awaitRefetchQueries: true
  });

  const onSubmit = async (values) => {
    const result = await insert({
      variables: {
        treatmentPlan: {
          patient_id: id,
          ...values
        }
      }
    });
    if (result?.data?.insertTreatmentPlan?.treatmentPlanData?.id) {
      const treatmentPlanId = result.data.insertTreatmentPlan.treatmentPlanData.id;
      navigate(`/dashboard/treatmentPlan/${id}/${treatmentPlanId}`); // Update with the correct path
    } else {
      console.error('failed to create treatment plan', result);
    }
  };
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} ml={5}>
        <Typography variant="h4" gutterBottom>
          Nuevo plan de tratamiento
        </Typography>
      </Stack>
      <Box px={{ xs: 1, sm: 2, md: 5 }}>
        <Card sx={{ boxShadow: 3 }}>
          <Box px={{ xs: 1, sm: 5 }} py={3}>
            <TreatmentPlanForm onSubmit={onSubmit} />
          </Box>
        </Card>
      </Box>
    </>
  );
}
