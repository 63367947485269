import { Button, Card, Container, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
// components
import Page from 'components/Page';

export default function Manager() {
  return (
    <Page title="Dental by Aronin">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Manager
          </Typography>
        </Stack>

        <Card sx={{ boxShadow: 3 }}>
          <Button component={RouterLink} to="groups">
            Manage Cognito groups
          </Button>
          <br />
          <Button component={RouterLink} to="users">
            Manage Cognito users
          </Button>
        </Card>
      </Container>
    </Page>
  );
}
