import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  Box
} from '@mui/material';

export const FileModal = ({ open, onClose, presignedDownloadUrl }) => {
  const [fileUrl, setFileUrl] = useState('');
  const [fileType, setFileType] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchFile = async () => {
      if (open && presignedDownloadUrl) {
        setLoading(true);
        try {
          console.log(presignedDownloadUrl);
          const response = await fetch(presignedDownloadUrl);
          const blob = await response.blob();
          const objectUrl = URL.createObjectURL(blob);
          setFileUrl(objectUrl);
          setFileType(blob.type);
        } catch (error) {
          console.error('Error fetching file:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchFile();

    return () => {
      if (fileUrl) {
        URL.revokeObjectURL(fileUrl);
      }
    };
  }, [open, presignedDownloadUrl]);

  const handleDownload = () => {
    if (!fileUrl) return;
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'downloaded_file';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      );
    }

    if (typeof fileType === 'string' && fileType.startsWith('image/')) {
      return <img src={fileUrl} alt="Preview" style={{ width: '100%', height: 'auto' }} />;
    }

    if (fileType === 'application/pdf') {
      return (
        <iframe
          src={fileUrl}
          title="PDF Preview"
          style={{ width: '100%', height: '500px' }}
          frameBorder="0"
        />
      );
    }

    return <p>No se puede visualizar este archivo. Descargue el archivo para verlo.</p>;
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Vista Previa</DialogTitle>
      <DialogContent>{renderContent()}</DialogContent>
      <DialogActions>
        <Button onClick={handleDownload} aria-label="Download file" disabled={loading}>
          Descargar
        </Button>
        <Button onClick={onClose} aria-label="Close dialog">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
